import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <nav className="light-blue lighten-1" role="navigation">
                <div className="nav-wrapper container">

                    <a id="logo-container" href="#" className="brand-logo">Stonksworth</a>

                    <ul className="right hide-on-med-and-down">
                        <li>
                            <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                        </li>
                        <li>
                            <NavLink tag={Link} className="text-dark" to="/normal-distribution">Norm Distribution</NavLink>
                        </li>
                    </ul>

                    <ul id="nav-mobile" className="sidenav">
                        <li>
                            <NavLink tag={Link} className="text-dark" to="/normal-distribution">Norm Distribution</NavLink>
                        </li>
                        <li>
                            <NavLink tag={Link} className="text-dark" to="/normal-distribution">Norm Distribution</NavLink>
                        </li>
                    </ul>

                    <a href="#" data-target="nav-mobile" className="sidenav-trigger">
                        <i className="material-icons">menu</i>
                    </a>
                </div>
            </nav>

        );
    }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <div class="row center">
                    <h5 class="header col s12 light">Stock price probabilities</h5>
                </div>
                <p class="light">Stonksworth provides stock price statistics and probabilities based on historical price changes.</p>
                <div class="row center">
                    <Link to="normal-distribution" className="btn waves-effect waves-light light-blue lighten-1">Normal Distribution page</Link>
                </div>
            </div>
        );
    }
}

import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import './Css/ChartJsStyles.css';
import $ from 'jquery';


export class NormalDistributionPage extends Component {
    static displayName = NormalDistributionPage.name;

    chartReference = {};
    distributionChartOptions = {
        plugins: {
            legend: {
                display: false,
            },
        },
        maintainAspectRatio: false
    };

    heatmapChartReferemce = {};
    heatmapChartOptions = {
        plugins: {
            legend: {
                display: false,
            },
        },
        maintainAspectRatio: false
    };

    constructor(props) {
        super(props);

        var today = new Date();
        this.state = {
            symbol: "SPY",
            showStartDate: false,
            startDate: today, //null,
            startDateStr: today.toLocaleDateString("en-US"), // DatePicker timezone issue
            startTime: "09:30:00", //"",
            endDate: today,
            endDateStr: today.toLocaleDateString("en-US"),
            endTime: "16:00:00",
            intervalInCents: 100,
            minimumPercentChanceForTailEnds: 0.5,
            distributionSet: null,
            loading: false,

            chartData: {
                labels: [],
                datasets: [
                    //{
                    //    label: 'Normal',
                    //    data: [65, 59, 80, 81, 56, 55, 40],
                    //    fill: false,
                    //    borderColor: 'rgb(75, 192, 192)',
                    //    tension: 0.1,
                    //},
                ],
            },
            heatmapChartData: {
                labels: [],
                datasets: [],
            }
        }

        this.getChart = this.getChart.bind(this);
        this.startDateChange = this.startDateChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);

        this.initPage();
    }

    initPage() {

        fetch('marketdates/getpreviousmarkethalfhourandnextmarketclose')
            .then(async response => {
                const responseData = await response.json();
                console.log("getpreviousmarkethalfhourandnextmarketclose response:");
                console.log(responseData);
                this.setState({
                    startDate: new Date(responseData.fromDate),
                    startDateStr: responseData.fromDateStr,
                    startTime: responseData.fromTimeStr,
                    endDate: new Date(responseData.toDate),
                    endDateStr: responseData.toDateStr
                    //endTime: 
                });
            });

    }


    getChart() {

        this.setState({
            distributionSet: null,
            errorMessage: null,
            loading: true
        });

        const distRequest = {
            "symbol": this.state.symbol,
            "startDate": this.state.startDateStr,
            "startTime": this.state.startTime,
            "endDate": this.state.endDateStr,
            "endTime": this.state.endTime,
            "intervalInCents": this.state.intervalInCents,
            "minimumPercentChanceForTailEnds": this.state.minimumPercentChanceForTailEnds
        };

        console.log("Request:");
        console.log(distRequest);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(distRequest)
        };

        fetch('pricedistribution/getdistribution', requestOptions)
            .then(async response => {

                //console.log("Response:");
                //console.log(response);

                const responseData = await response.json();

                console.log("Response Data:");
                console.log(responseData);

                // check for error response
                if (!response.ok) {
                    const error = (responseData && responseData.message) || response.status; // get error message from body or default to response status
                    return Promise.reject(error);
                }

                //if (responseData.errorMessage !== null && responseData.errorMessage !== "") {
                //    // reset interval and min percentage bc likely that combo of those values caused error
                //    this.setState({ intervalInCents: 100, minimumPercentChanceForTailEnds: 0.5, loading: false });
                //    alert(responseData.errorMessage);
                //    return;
                //}

                //console.log("Start Date Returned:")
                //console.log(responseData.currentPrice.date);

                var heatmapChartData = responseData.heatmapChartData;
                if (heatmapChartData == null) {
                    heatmapChartData = {
                        labels: [],
                        datasets: [],
                    };
                }

                this.setState({
                    distributionSet: responseData,
                    errorMessage: responseData?.errorMessage,
                    chartData: {
                        labels: (responseData?.labels === null ? [] : responseData.labels),
                        datasets: (responseData?.datasets === null ? [] : responseData.datasets),
                    },
                    heatmapChartData: heatmapChartData, //responseData.heatmapChartData,
                    //startDate: new Date(responseData.startDateStr),
                    //startTime: responseData.startTimeStr,
                    showStartDate: false,
                    loading: false
                });

                // Note: excepts labels and datasets props on response, and response object be in the following chart-js schema:
                //this.setState({
                //    data: {
                //        labels: ['Jan', 'Feb', 'M', 'A', 'May', 'June', 'July'],
                //        datasets: [
                //            {
                //                label: 'Sales',
                //                data: [75, 1, 82, 81, 56, 55, 40],
                //                fill: false,
                //                borderColor: 'rgb(75, 192, 192)',
                //                tension: 0.1,
                //            },
                //        ],
                //    }
                //});

                //console.log("Chart reference:");
                //console.log(this.chartReference);

                try {
                    let lineChart = this.chartReference.chartInstance;
                    lineChart.update();
                } catch (dontCareExc) {
                    console.log("chart js exc - don't care...?")
                }

                try {
                    this.heatmapChartReferemce?.chartInstance?.update();
                } catch (dontCareExc) {
                    console.log("chart js heatmap exc - don't care...?")
                }

            })
            .catch(error => {
                this.setState({ loading: false });
                console.log(typeof error);
                console.error('There was an error!', error);
            });
    }

    componentDidMount() {
        //$(document).ready(function () {
        //    $('select').formSelect();
        //});
    }

    startDateChange(date) {
        console.log("start date change:");
        console.log(date);
        console.log(date.toLocaleDateString("en-US"))
        this.setState({ startDate: date, startDateStr: date.toLocaleDateString("en-US") })
    }

    endDateChange(date) {
        console.log("end date change:");
        console.log(date);
        console.log(date.toLocaleDateString("en-US"))
        this.setState({ endDate: date, endDateStr: date.toLocaleDateString("en-US") })
    }

    render() {

        return (
            <div>
                <div className="container">
                    <div className="section">
                        <div className="row">
                            <div className="col l2 s4">
                                Stonk <input type="text" value={this.state.symbol} onChange={e => this.setState({ symbol: e.target.value })} />
                            </div>
                            <div className="col l2 s4">
                                From
                                <DatePicker
                                    //onSelect={handleDateSelect} //when day is clicked
                                    selected={this.state.startDate}
                                    onChange={this.startDateChange}
                                    wrapperClassName="date-input"
                                />
                            </div>
                            <div className="col l2 s4">
                                <select
                                    value={this.state.startTime}
                                    onChange={(event) => this.setState({ startTime: event.target.value })}
                                    style={{ display: "block", marginTop: "1.5em" }} >
                                    <option value="">Now</option>
                                    <option value="09:30:00">Open</option>
                                    <option value="10:00:00">10 AM</option>
                                    <option value="10:30:00">10:30 AM</option>
                                    <option value="11:00:00">11 AM</option>
                                    <option value="11:30:00">11:30 AM</option>
                                    <option value="12:00:00">12 PM</option>
                                    <option value="12:30:00">12:30 PM</option>
                                    <option value="13:00:00">1 PM</option>
                                    <option value="13:30:00">1:30 PM</option>
                                    <option value="14:00:00">2 PM</option>
                                    <option value="14:30:00">2:30 PM</option>
                                    <option value="15:00:00">3 PM</option>
                                    <option value="15:30:00">3:30 PM</option>
                                    <option value="16:00:00">Close</option>
                                </select>
                            </div>
                            <div className="col l2 s5">
                                To
                                <DatePicker
                                    //onSelect={handleDateSelect} //when day is clicked
                                    selected={this.state.endDate}
                                    onChange={this.endDateChange}
                                />
                            </div>
                            <div className="col l2 s4">
                                <select
                                    value={this.state.endTime}
                                    onChange={(event) => this.setState({ endTime: event.target.value })}
                                    style={{ display: "block", marginTop: "1.5em" }} >
                                    <option value="09:30:00">Open</option>
                                    <option value="10:00:00">10 AM</option>
                                    <option value="10:30:00">10:30 AM</option>
                                    <option value="11:00:00">11 AM</option>
                                    <option value="11:30:00">11:30 AM</option>
                                    <option value="12:00:00">12 PM</option>
                                    <option value="12:30:00">12:30 PM</option>
                                    <option value="13:00:00">1 PM</option>
                                    <option value="13:30:00">1:30 PM</option>
                                    <option value="14:00:00">2 PM</option>
                                    <option value="14:30:00">2:30 PM</option>
                                    <option value="15:00:00">3 PM</option>
                                    <option value="15:30:00">3:30 PM</option>
                                    <option value="16:00:00">Close</option>
                                </select>
                            </div>
                            <div className="col l2 s2">
                                {/*<a className="waves-effect waves-light btn">Get Chart</a>*/}
                                <button className="btn waves-effect waves-light light-blue lighten-1" type="submit" name="action" onClick={this.getChart.bind(this)} style={{ marginTop: "1.5em" }}>
                                    Get Charts
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "none" }}>
                        <div>
                            <div style={{ float: "left" }}>
                                Stonk <input type="text" className="stonk-input" value={this.state.symbol} onChange={e => this.setState({ symbol: e.target.value })} />
                            </div>
                            <div style={{ float: "left" }}>
                                <div style={{ float: "left", paddingLeft: "1em" }}>
                                    From
                                </div>
                                <div style={{ float: "left", paddingLeft: "1em" }}>
                                    <DatePicker
                                        //onSelect={handleDateSelect} //when day is clicked
                                        selected={this.state.startDate}
                                        onChange={this.startDateChange}
                                        wrapperClassName="date-input"
                                    />
                                </div>
                                <div style={{ float: "left", paddingLeft: ".21em" }}>
                                    <select className="time-select"
                                        value={this.state.startTime}
                                        onChange={(event) => this.setState({ startTime: event.target.value })}
                                        style={{ float: "left" }} >
                                        <option value="">Now</option>
                                        <option value="09:30:00">Open</option>
                                        <option value="10:00:00">10 AM</option>
                                        <option value="10:30:00">10:30 AM</option>
                                        <option value="11:00:00">11 AM</option>
                                        <option value="11:30:00">11:30 AM</option>
                                        <option value="12:00:00">12 PM</option>
                                        <option value="12:30:00">12:30 PM</option>
                                        <option value="13:00:00">1 PM</option>
                                        <option value="13:30:00">1:30 PM</option>
                                        <option value="14:00:00">2 PM</option>
                                        <option value="14:30:00">2:30 PM</option>
                                        <option value="15:00:00">3 PM</option>
                                        <option value="15:30:00">3:30 PM</option>
                                        <option value="16:00:00">Close</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{ float: "left", paddingLeft: "1em" }}>
                                <div style={{ float: "left" }}>To</div>
                                <div style={{ float: "left", paddingLeft: "1em" }}>
                                    <DatePicker
                                        //onSelect={handleDateSelect} //when day is clicked
                                        selected={this.state.endDate}
                                        onChange={this.endDateChange}
                                    />
                                </div>
                                <div style={{ float: "left", paddingLeft: ".21em" }}>
                                    <select className="time-select"
                                        value={this.state.endTime}
                                        onChange={(event) => this.setState({ endTime: event.target.value })}>
                                        <option value="09:30:00">Open</option>
                                        <option value="10:00:00">10 AM</option>
                                        <option value="10:30:00">10:30 AM</option>
                                        <option value="11:00:00">11 AM</option>
                                        <option value="11:30:00">11:30 AM</option>
                                        <option value="12:00:00">12 PM</option>
                                        <option value="12:30:00">12:30 PM</option>
                                        <option value="13:00:00">1 PM</option>
                                        <option value="13:30:00">1:30 PM</option>
                                        <option value="14:00:00">2 PM</option>
                                        <option value="14:30:00">2:30 PM</option>
                                        <option value="15:00:00">3 PM</option>
                                        <option value="15:30:00">3:30 PM</option>
                                        <option value="16:00:00">Close</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div style={{ clear: "both", paddingTop: "1em", display: "none" }}>
                            <input type="button" value="Get Chart" onClick={this.getChart.bind(this)} />
                        </div>
                        {this.state.errorMessage &&
                            <div style={{ color: "red" }}>
                                {this.state.errorMessage}
                            </div>
                        }
                    </div>

                </div>
                <div>
                    <div>
                        {this.state.loading
                            &&
                            <div className="row center">
                                <h5 className="header col s12 light">Loading</h5>
                                <div className="progress">
                                    <div className="indeterminate light-blue lighten-3"></div>
                                </div>
                            </div>
                        }
                        <div style={{ display: this.state.distributionSet && !this.state.loading ? "" : "none" }} >
                            <article className="heatmap-canvas-container">
                                <Line
                                    data={this.state.heatmapChartData} options={this.heatmapChartOptions}
                                    ref={(reference) => this.heatmapChartReferemce = reference}
                                />
                            </article>
                        </div>
                        <div style={{ display: this.state.distributionSet && !this.state.loading ? "" : "none" }} >
                            <article className="distribution-canvas-container">
                                <Line
                                    data={this.state.chartData} options={this.distributionChartOptions}
                                    ref={(reference) => this.chartReference = reference}
                                />
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
